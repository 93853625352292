import React, {useState} from 'react';
import {
  ColorWrapper,
  Container,
  PageContainer,
  ImageWrapper,
  CreatorBlock,
  CreatorView,
  CreatorName,
  CreatorJob,
  TitlePage,
  ContainerPerson,
  PersonLeftColumn,
  PersonRightColumn,
  PersonName,
  PersonJob,
  PersonDescription,
  FlexLine,
  SocialIcon,
  SectionText,
  CloseButton,
  CreatorsViewText,
} from './styles';
import {Parallax} from 'react-scroll-parallax';
import bg from './images/background.jpg';
import IconMail from '../../../images/mail2.svg';
import IconWeb from '../../../images/www.svg';
import IconClose from '../../../images/close.svg';
import IconIn from '../../../images/lidkedin.svg';
import people from './people.js';

const PersonView = ({person, setSelectedPerson}) => {
  return (
    <ContainerPerson>
      <CloseButton src={IconClose} onClick={() => setSelectedPerson(null)} />
      <PersonLeftColumn>
        <PersonName>{person.name}</PersonName>
        <PersonJob>{person.job}</PersonJob>
        <PersonDescription>{person.description}</PersonDescription>
        {person.mail && (
          <FlexLine href={`mailto:${person.mail}`} style={{marginTop: '1rem'}}>
            <SocialIcon src={IconMail} />
            <CreatorsViewText>{person.mail}</CreatorsViewText>
          </FlexLine>
        )}
        {person.linkedin && (
          <FlexLine
            href={`https://${person.linkedin}`}
            style={{marginTop: '1rem'}}>
            <SocialIcon src={IconIn} />
            <CreatorsViewText>{person.linkedin}</CreatorsViewText>
          </FlexLine>
        )}
        {person.domain && (
          <FlexLine href={person.domain.url} style={{marginTop: '1rem'}}>
            <SocialIcon src={IconWeb} />
            <CreatorsViewText>{person.domain.name}</CreatorsViewText>
          </FlexLine>
        )}
      </PersonLeftColumn>
      <PersonRightColumn>
        <video src={person.video} autoPlay controls={false} loop />
      </PersonRightColumn>
    </ContainerPerson>
  );
};

const CreatorsPage = () => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  return (
    <PageContainer backgroundColor={'#e09087'} style={{paddingBottom: 0}}>
      <Parallax y={[20, -20]}>
        <ImageWrapper srcBg={bg} />
      </Parallax>
      <Parallax y={[8, -160]} className="second-parallax">
        <ColorWrapper bgColor="#525252">
          <TitlePage>TWÓRCY MUZEUM</TitlePage>
          {selectedPerson !== null ? (
            <PersonView
              person={people[selectedPerson]}
              setSelectedPerson={setSelectedPerson}
            />
          ) : (
            <>
              <Container style={{display: 'block'}}>
                <SectionText>
                  &emsp;&emsp;Wirtualne Muzeum Jana Szczepkowskiego to projekt
                  tworzony przez ludzi z&nbsp;pasją do kultury. Wiemy, że otacza
                  nas niesamowite dziedzictwo, które należy ocalić od
                  zapomnienia. Jesteśmy gotowi na odważne projekty i&nbsp;trudne
                  zadania. Nasz projekt nie kończy się wraz z&nbsp;uruchomieniem
                  strony muzeum. On dopiero się zaczyna. <br /> <br />
                  Zapraszamy do współpracy.
                </SectionText>
              </Container>
              <Container>
                {people.map(({name, job, photo1, photo2}, i) => (
                  <CreatorBlock onClick={() => setSelectedPerson(i)}>
                    <CreatorView src={photo1} src2={photo2} />
                    <CreatorName>{name}</CreatorName>
                    <CreatorJob>{job}</CreatorJob>
                  </CreatorBlock>
                ))}
              </Container>
            </>
          )}
        </ColorWrapper>
      </Parallax>
    </PageContainer>
  );
};

export default CreatorsPage;
