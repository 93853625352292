import React from 'react';
import SEO from '../../components/seo';
import CreatorsPage from '../../components/desktop/CreatorsPage/CreatorsPage';

const About = () => {
  return (
    <>
      <SEO title={'Twórcy muzeum'} />
      <CreatorsPage />
    </>
  );
};

export default About;
