const people = [
  {
    name: 'Aneta Majak',
    job: 'Koordynator organizacyjny',
    photo1: require('./images/Aneta_1_min.jpg'),
    photo2: require('./images/Aneta_2_min.jpg'),
    photoFull: require('./images/Aneta_2.jpg'),
    video: require('./videos/Aneta.mp4'),
    description:
      'Koordynatorka organizacyjna. Całą papierologią projektu mogłaby wytapetować pokój. Jakby spojrzeć na billingi jej rozmów to są długie niczym lista sukcesów Jana Szczepkowskiego. Zajmuje się też pozyskiwaniem partnerów i patronów projektu.',
    mail: 'a.majak@mckmilanowek.pl',
    domain: {
      url: 'https://mckmilanowek.pl',
      name: 'mckmilanowek.pl',
    },
    linkedin: '',
  },
  {
    name: 'Tobiasz Bułynko',
    job: 'koordynator kreatywny',
    photo1: require('./images/Tobiasz_1_min.jpg'),
    photo2: require('./images/Tobiasz_2_min.jpg'),
    photoFull: require('./images/Tobiasz_2.jpg'),
    video: require('./videos/Tobiasz.mp4'),
    description:
      'Według alfabetu jest prawie pierwszy na naszej liście. Za to „pierwszy” jest w tym projekcie. Niczym Jan Szczepkowski, kiedy przewodniczył Radzie Centralnego Instytutu Kultury. Człowiek, z którego głowy wypłynęła wizja tego, gdzie teraz jesteście. Odpowiedzialny za digitalizację obiektów, przygotowanie skanów 3D oraz identyfikaje wizualną muzeum. Uwierzycie, że nie ma Facebooka?',
    mail: 'tobiasz.bulynko@gmail.com',
    domain: '',
    linkedin: '',
  },
  {
    name: 'Karolina Polkowska',
    job: 'specjalistka 3D',
    photo1: require('./images/Karo_1_min.jpg'),
    photo2: require('./images/Karo_2_min.jpg'),
    photoFull: require('./images/Karo_2.jpg'),
    video: require('./videos/Karo.mp4'),
    description:
      'Dzięki Karolinie wszystko kręci się dookoła. A szczególnie rzeźby 3D na naszej stronie, które potrafiła wykonać w jakiś szatański sposób. Nawet płaskorzeźbione supraporty (płasko!) z ryzalitu na północnej ścianie Banku Gospodarstwa Krajowego nabierają trzeciego wymiaru.',
    mail: 'karolpolko@gmail.com',
    domain: {
      url: 'http://polkowskart.pl',
      name: 'polkowskart.pl',
    },
    linkedin: '',
  },
  {
    name: 'Michał Słowiński',
    job: 'redaktor tekstu',
    photo1: require('./images/Michal_1_min.jpg'),
    photo2: require('./images/Michal_2_min.jpg'),
    photoFull: require('./images/Michal_2.jpg'),
    video: require('./videos/Michal.mp4'),
    description:
      'Wszystko, co czytacie na tej stronie, zostało w większym lub mniejszym stopniu wyklikane na jego laptopie. Ale podobno lubi tę robotę i szukanie różnym nawiązań do twórczości Jana Szczepkowskiego jest bycze niczym gipsowy Byczek (1909 r.) będący jedną z wcześniejszych prac artysty z jego krakowskiego okresu… O, znowu zaczyna!',
    mail: 'm.slowinski@mckmilanowek.pl',
    domain: {
      url: 'https://mckmilanowek.pl',
      name: 'mckmilanowek.pl',
    },
    linkedin: '',
  },
  {
    name: 'Kuba Duda',
    job: 'Programista',
    photo1: require('./images/Kuba_1_min.jpg'),
    photo2: require('./images/Kuba_2_min.jpg'),
    photoFull: require('./images/Kuba_2.jpg'),
    video: require('./videos/Kuba.mp4'),
    description:
      'Nasz budowniczy strony. Skupiony na swojej pracy niczym Stanisław Moniuszko na cokole przed Teatrem Wielkim. Nie wiemy, czy gra na jakimś instrumencie, ale wiemy, że programowanie idzie mu jak z nut.',
    mail: 'kubakoduje@gmail.com',
    domain: {
      url: 'https://kubakoduje.pl',
      name: 'kubakoduje.pl',
    },
    linkedin: 'linkedin.com/in/kuba-duda/',
  },
  {
    name: 'Mateusz Orłowski',
    job: 'Programista',
    photo1: require('./images/Mateusz_1_min.jpg'),
    photo2: require('./images/Mateusz_2.jpg'),
    photoFull: require('./images/Mateusz_2.jpg'),
    video: require('./videos/Mateusz.mp4'),
    description:
      'Mateusz buduję stronę internetową i nie używa przy tym myszki. Bo tak chce, a nie dlatego że nie ma. Jest to robota żmudna niczym wykonanie makiety łuku triumfalnego Bohaterów Warszawy na Placu na Rozdrożu. Wołamy na niego Mati. Dobra. Nie wołamy.',
    mail: 'mateusz.orlowski95@gmail.com',
    domain: '',
    linkedin: 'linkedin.com/in/mateusz-orłowski/',
  },
  {
    name: 'Ola Andrzejczak',
    job: 'tłumaczka na język angielski',
    photo1: require('./images/Ola_1_min.jpg'),
    photo2: require('./images/Ola_2_min.jpg'),
    photoFull: require('./images/Ola_2.jpg'),
    video: require('./videos/Ola.mp4'),
    description:
      'Ola nas tłumaczy. Ale nie wtedy kiedy coś nawywijamy, tylko tłumaczy nas na język angielski. Bo uważamy, że warto, aby John Szczepkowski wyszedł poza nasze granice. A wirtualne muzeum daje temu unlimited możliwości.',
    mail: 'aleksandra.katarzyna.andrzejczak@gmail.com',
    domain: '',
    linkedin: '',
  },
  {
    name: 'Joshua Szpilarski',
    job: 'lektor',
    photo1: require('./images/Josh_1_min.jpg'),
    photo2: require('./images/Josh_2_min.jpg'),
    photoFull: require('./images/Josh_2.jpg'),
    video: require('./videos/Josh.mp4'),
    description:
      'Głos tego projektu. Niech was nie zmyli obco brzmiące imię, bo Josh czyta najpiękniejszą polszczyzną. Choć języki obce też nie są mu obce. Obudzony w środku nocy bez zająknięcia powie: Kriegsgräberabteilung!',
    mail: 'josh.szpilarski@gmail.com',
    domain: '',
    linkedin: '',
  },
];

export default people;
